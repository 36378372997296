import React, { Component } from 'react';
import { register } from '../repository';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// Load the full build.
var _ = require('lodash');
// Load the core build.
var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
var fp = require('lodash/fp');

class Register extends Component {
 constructor() {
    super();
    this.state = { firstName: '', lastName: '', emailAddress: '', name: '', password: '', reenterPassword:'' };
    this.handleInputChange =this.handleInputChange.bind(this);
    this.submitRegister =this.submitRegister.bind(this);
 }

handleInputChange(event) {
     this.setState({[event.target.name]: event.target.value})
}

submitRegister(event){
    if ( _.isEmpty(this.state.email) || _.isEmpty(this.state.password) ) {
        alert("Email and Password are required");
    }
    else {
        if ( this.state.password === this.state.reenterPassword) {
            event.preventDefault();
            register(this.state)
                .then(token => window.location = '/')
                .catch(err => alert(err));
        }
        else {
            confirmAlert({
                title: 'Password Validation',
                message: 'The passwords do not match.',
                buttons: [
                    {
                    label: 'OK',
                    onClick: () => {}
                    }
                ]
            })
        }
    }
}

render() {
 return (
 <div className="container">
 <hr/>
    <div className="col-sm-8 col-sm-offset-2">
        <div className="panel panel-primary">
        <div className="panel-heading">
        <h3>Create Account</h3>
    </div>
    <div className="panel-body">
        <form onSubmit={this.submitRegister}>
        <div className="form-group">
            <label>First Name:</label>
            <input type="text" className="form-control" name="firstName" onChange={this.handleInputChange}/>
        </div>
        <div className="form-group">
            <label>Last Name:</label>
            <input type="text" className="form-control" name="lastNname" onChange={this.handleInputChange}/>
        </div>
        <div className="form-group">
            <label>UserId/Email:</label>
            <input type="text" className="form-control" name="emailAddress" onChange={this.handleInputChange}/>
        </div>
        <div className="form-group">
            <label>Password:</label>
            <input type="password" className="form-control" name="password" onChange={this.handleInputChange}/>
        </div>
        <div className="form-group">
            <label>Re-Enter Password:</label>
            <input type="password" className="form-control" name="reenterPassword" onChange={this.handleInputChange}/>
        </div>
        <button type="submit" className="btn button-green">Submit</button>
        </form>
        </div>
        </div>
    </div>
 </div>
 );
 }
 }
 export default Register;