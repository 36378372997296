import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import header from '../images/wishlistBanner.png'; 


class Header extends Component {


    render() {
        return (

                <Container>
                    <Row>
                        <Col>
                        <img src={header} alt="WishList" />
                        </Col>
                    </Row>
                </Container>
                
        );
    }
}
 
export default Header;
