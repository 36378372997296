import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-dropdown'
import MessageModal from './messageModal';
import { getGroupsByMember, isAuthenticated, getMember, hasMember, updatePurchased } from '../repository';
import 'react-dropdown/style.css'
import 'bootstrap/dist/css/bootstrap.css';
import '../groups.css'

// Load the full build.
var _ = require('lodash');
// Load the core build.
var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
var fp = require('lodash/fp');


//import './Groups.css';
 
class Groups extends Component {

    constructor(props) {
        super(props);
        console.log(this.props);

        this.state = {
            groupData : [{group_Id: '77', 
                          group_name: "BoB"}],
            selectedGroup: "",
            groups: [],
            members: [],
            filters: ["All", "Not Purchased", "Purchased"],
            selectedMember  : "",
            selectedFilter  : "",
            currentMember   : null,
            list: [],
            groupMembers: []      
        };

        this.columns = [{
            dataField: 'list_Id',
            hidden: true
          }, {
            dataField: 'description',
            text: 'Description',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
            formatter: this.cellFormatter
          }, {
            dataField: 'price',
            text: 'Price',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
          }, {
            dataField: 'available_at',
            text: 'Available At',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor:  'rgb(115, 214, 118)' }
          }, {
              dataField: 'size',
              text: 'Size/Quantity',
              headerStyle: { backgroundColor: 'white' },
              style: { backgroundColor: 'rgb(115, 214, 118)' },
          }, {
              dataField: 'purchased',
              text: 'Mark as Purchased',
              headerStyle: { backgroundColor: 'white' },
              style: { backgroundColor: 'rgb(115, 214, 118)' },
              align: 'center',
              formatter: this.purchasedCellFormatter,
              events: {
                  onClick: (e, column, columnIndex, row, rowIndex) => {
                      updatePurchased(row.list_Id, row.purchased).then(response => {
                        getGroupsByMember().then(response =>  {
                            this.setState({ groupData: response});
                            var groups = this.processGroups(response);
                            this.setState({ groups: groups});
                            this.selectGroup(this.state.selectedGroup);
                            this.selectMember(this.state.selectedMember);
                        })
                        .catch(e => {
                            console.log(e);
                        });                          
                    });
                  }
              }
          }];

        this.componentDidMount = this.componentDidMount.bind(this);
        this.selectGroup = this.selectGroup.bind(this);
        this.selectMember = this.selectMember.bind(this);
        this.selectFilter = this.selectFilter.bind(this);
    }

    componentDidMount() {
       
        if (isAuthenticated) {
            if (!hasMember()) {
                getMember().then(response =>  {
                    getGroupsByMember().then(response =>  {
                        this.setState({ groupData: response});
                        var groups = this.processGroups(response);
                        this.setState({ groups: groups});
                        this.setState({selectedGroup: groups[0]});
                        this.selectGroup(groups[0]);
                        console.log(this.state.groupData);
                    })
                    .catch(e => {
                        console.log(e);
                    });
                })
                .catch(e => {
                    console.log(e);
                });
            } else {
                getGroupsByMember().then(response =>  {
                    this.setState({ groupData: response});
                    var groups = this.processGroups(response);
                    this.setState({ groups: groups});
                    this.setState({selectedGroup: groups[0]});
                    this.selectGroup(groups[0]);
                    console.log(this.state.groupData);
                })
                .catch(e => {
                    console.log(e);
                });
            }
        }
    }

    cellFormatter = function(cell, row, rowIndex) {
        if (row.link === "")
            return (<div>{cell}</div>);
        else
           return (<div><a href={row.link} target="_blank">{cell}</a></div>);
    }

    purchasedCellFormatter = function(cell, row, rowindex) {
        let currentMember = JSON.parse(localStorage.getItem('member'));
        if(row.purchased === "0")
            return (<div><button className="purchased-button bought-it">Bought It</button></div>);
        else if(row.purchased === "1" && row.purchased_by === currentMember.member_Id)
            return (<div><button className="purchased-button un-buy">Un-Buy</button></div>);
        else if(row.purchased === "1")
            return(<div>Purchased</div>);
    }

    processGroups = function(response) {
        var groupDropDown = [];

        response.forEach(resp => {
             groupDropDown.push({value: resp.groupId, label: resp.groupName});
        });

        return groupDropDown;
    }

    selectGroup = function(selected) {

        var member = _.find(this.state.groupData, {'groupId' : selected.value});
        let currentMember = JSON.parse(localStorage.getItem('member'));
        var memberDropDown = [];

        member.members.forEach(member => {
            if(member.member_Id !== currentMember.member_Id) {
                memberDropDown.push({value: member.member_Id, label: member.firstName + " " + member.lastName} );
            }
        });

        this.setState({selectedGroup: selected});
        this.setState({groupMembers: member.members})
        this.setState({members : memberDropDown});
    }

    selectMember = function(selected) {

        console.log("selectmembers");
        console.log(this.state.groupMembers);
     
        var groupMember = _.find(this.state.groupMembers, {'member_Id' : selected.value});

        console.log(groupMember)

        // member..forEach(member => {
        //     console.log(member.member_Id);
        //      memberDropDown.push({value: member.member_Id, label: member.member.firstName + " " + member.member.last_name} );
        // });


        this.setState({selectedMember: selected});
        this.setState({list : groupMember.lists});
        this.setState({currentMember : groupMember});
        this.setState({selectedFilter : ""});
    }

    selectFilter = function(selected) {
        if(this.state.currentMember !== undefined && this.state.currentMember !== null) {
            var curMemberList = this.state.currentMember.lists;
            var filteredList = [];
            if(selected.value === "Purchased") {
                filteredList = curMemberList.filter(p => p.purchased === "1");
            }
            else if(selected.value ==="Not Purchased") {
                filteredList = curMemberList.filter(p => p.purchased === "0");
            }
            else if(selected.value === "All") {
                filteredList = curMemberList;
            }

            this.setState({list : filteredList});
        } 
        this.setState({selectedFilter : selected});
    }

    render() {
        return (
            <div >
                <Container>
                    <Row></Row>
                    <Row>
                        <Col  md={3}>
                            <Dropdown options={this.state.groups} onChange={this.selectGroup} value={this.state.selectedGroup} />
                        </Col>
                        <Col  md={3}>
                            <Dropdown options={this.state.members} onChange={this.selectMember} value={this.state.selectedMember} placeholder="Select an Member" />
                        </Col>
                        <Col  md={3}>
                            <Dropdown options = {this.state.filters} onChange={this.selectFilter} value={this.state.selectedFilter} placeholder ="Select a Filter"/>
                        </Col>
                        <Col  md={3}>
                            <MessageModal groupId={this.state.selectedGroup.value}  groupName={this.state.selectedGroup.label}/>
                        </Col>
                    </Row>
                    <Row>
           
                    </Row>
                    <br></br>
                    <Row>
                        <Col>
                        <BootstrapTable  data={this.state.list} 
                                         columns={this.columns} 
                                         version='4'
                                         striped
                                         hover
                                         condensed
                                         keyField='list_Id'></BootstrapTable>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
 
export default Groups;
