import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { forgotPassword } from '../repository';

class Login extends Component {
 constructor() {
 super();
 this.state = { name: '' };
 this.handleInputChange =this.handleInputChange.bind(this);
 this.submitForgotPassword =this.submitForgotPassword.bind(this);
 }

handleInputChange(event) {
 this.setState({[event.target.name]: event.target.value})
 }

submitForgotPassword(event){
 event.preventDefault();
    forgotPassword(this.state)
    .then(token => {alert("An Email will be sent with a link to reset your password"); window.location = '/'})
    .catch(err => alert(err));
 }

render() {
 return (
 <Container>
 <hr/>
    <Row>
        <Col md={4}></Col>
        <Col>
            <h3>Forgot Password </h3>
        </Col>
        <Col md={4}></Col>
    </Row>

        <form onSubmit={this.submitForgotPassword}>
        <Row >
            <Col md={4}></Col>
            <Col>
                <label>Email:</label>
                <input type="email" className="form-control" name="name" onChange={this.handleInputChange}/>
            </Col>
            <Col md={4}></Col>
        </Row>
        <Row >
            <Col><br></br></Col>
        </Row>
        <Row>
        <Col> <br></br> </Col> 
        </Row>
        <Row>
            <Col md={4}></Col>
            <Col md={4}>
                 <button type="submit" className="btn btn-primary">Submit</button>
            </Col>
            <Col md={4}></Col>
        </Row>
        </form>
 </Container>
 );
 }
 }
 export default Login;