import React, { Component } from 'react';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { createGroup } from '../repository';

// Load the full build.
var _ = require('lodash');
// Load the core build.
var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
var fp = require('lodash/fp');

class CreateGroup extends Component {
 constructor() {
    super();
    this.state = { groupName: '',  emailList: '' };
    this.handleInputChange =this.handleInputChange.bind(this);
    this.submitCreateGroup =this.submitCreateGroup.bind(this);
 }

handleInputChange(event) {
   this.setState({[event.target.name]: event.target.value})
}

submitCreateGroup(event){
    if ( _.isEmpty(this.state.emailList) || _.isEmpty(this.state.groupName) ) {
        alert("Email(s) and group name are required");
    }
    else {
        event.preventDefault();
        createGroup(this.state)
            .then(token => window.location = '/')
            .catch(err => alert(err));
     }

 }

render() {
 return (
 <Container>
 <hr/>
    <Row >
         <Col md={4}></Col>
        <Col>
              <h3>Create Group </h3>
        </Col>
        <Col md={4}></Col>
    </Row>
    <div className="panel-body">
        <form onSubmit={this.submitCreateGroup}>
        <Row className="form-group">
            <Col md={4}></Col>
            <Col>
                <label>Group Name:</label>
                <input type="text" className="form-control" name="groupName" onChange={this.handleInputChange}/>
            </Col>
            <Col md={4}></Col>
        </Row>
        <Row>
            <Col md={4}></Col>
            <Col>
                <label>Invite (Email seperated by comma):</label>
                <input type="text" className="form-control" name="emailList" onChange={this.handleInputChange}/>
            </Col>
            <Col md={4}></Col>
        </Row>
        <Row><br></br></Row>
        <Row>
            <Col md={4}></Col>
            <Col>
               <button type="submit" className="btn button-green">Submit</button>
            </Col>
            <Col md={4}></Col>
        </Row>
        </form>
    </div>
 </Container>
 );
 }
 }
 export default CreateGroup;