import React, {Component} from 'react';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-dropdown/style.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import IosInformationCircleOutline from '../../node_modules/react-ionicons/lib/IosInformationCircleOutline'
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor/dist/react-bootstrap-table2-editor';
import { updateList, isAuthenticated, getMyList, deleteMyListItems, addListItem } from '../repository';
import '../mylist.css';

// Load the full build.
var _ = require('lodash');
// Load the core build.
var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
var fp = require('lodash/fp');

const cellEdit = cellEditFactory({
    mode: 'click',
    style: { backgroundColor: 'rgb(115, 214, 118)' },
    afterSaveCell: (oldValue, newValue, row, column) => { 
        updateList(row.list_Id, column.dataField, newValue);
     }
  });

  function anyCheckbox() {
    var inputElements = document.getElementsByTagName("input");
    for (var i = 0; i < inputElements.length; i++)
        if (inputElements[i].type == "checkbox")
            if (inputElements[i].checked)
                return true;
    return false;
}

function validateAddItemElements(addItemElements) {
    
}

class MyList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            disabledDeleteButton: true,
            selectedItems: [],
            showModal: false,
            showEditModal: false,
            description: '',
            price: '',
            availableAt: '',
            link: '',
            size: ''
        };

        this.columns = [{
            dataField: 'list_Id',
            hidden: true
        }, {
            dataField: 'description',
            text: 'Description',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
            type: 'string'
        }, {
            dataField: 'price',
            text: 'Price',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)'},
            type: 'string'
        }, {
            dataField: 'available_at',
            text: 'Available At',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
            type: 'string'
        }, {
            dataField: 'link',
            text: 'Link',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
            type: 'string'
        }, {
            dataField: 'size',
            text: 'Size/Quantity',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
            type: 'string'
        }];

        this.componentDidMount = this.componentDidMount.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.handleShowAddItemModal = this.handleShowAddItemModal.bind(this);
        this.handleHideAddItemModal = this.handleHideAddItemModal.bind(this);
        this.addItem = this.addItem.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleShowEditItemModal = this.handleShowEditItemModal.bind(this);
        this.handleHideEditItemModal = this.handleHideEditItemModal.bind(this);
    }

    componentDidMount() {
        if(isAuthenticated) {
            getMyList().then(response => {
                this.setState({list: response});
            })
        }
    }

    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value})
     }

    selectRow = {
        mode: 'checkbox',
        blurToSave: true,
        hideSelectAll: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            var selectedList = this.state.selectedItems;
            if(anyCheckbox()) {
                if(isSelect) {
                    selectedList.push(row.list_Id);
                } else {
                    selectedList.pop(row.list_ID);
                }
                this.setState({selectedItems: selectedList});
                this.setState({disabledDeleteButton: false});
            } else {
                selectedList.pop(row.list_Id);
                this.setState({disabledDeleteButton: true});
            }
        }
      };

    deleteItem() {
        deleteMyListItems(this.state.selectedItems).then(response => {
            this.componentDidMount();
            this.setState({disabledDeleteButton: true});
        })
    }

    handleShowAddItemModal(){
        this.setState({showModal: true});
    }

    handleHideAddItemModal() {
        this.setState({showModal: false});
    }

    handleShowEditItemModal(){
        this.setState({showEditModal: true});
    }

    handleHideEditItemModal(){
        this.setState({showEditModal: false});
    }

    addItem() {
        var addItemElements = document.getElementsByClassName("form-control");
        // var valid = validateAddItemElements(addItemElements);
        addListItem(addItemElements[0].value, addItemElements[1].value, addItemElements[2].value, addItemElements[3].value, addItemElements[4].value)
        .then(response => {
            this.handleHideAddItemModal();
            this.componentDidMount();
        });
    }

    render() {
        return (
            <div >
                <Container>
                    <Row>
                        <Col>
                            <div><b>How do I edit my list?</b>          
                                <IosInformationCircleOutline onClick={this.handleShowEditItemModal}></IosInformationCircleOutline>
                            </div>
                        </Col>
                    </Row>     
                    <Row>
                        <Col>
                        <BootstrapTable  data={this.state.list} 
                                         columns={this.columns}
                                         version='4'
                                         striped
                                         keyField='list_Id'
                                         cellEdit={cellEdit}
                                         selectRow={this.selectRow}></BootstrapTable>
                        </Col>
                    </Row>
                    <Row className="float-right">
                        <Col>
                            <Button variant='light' onClick={this.handleShowAddItemModal}>Add New Item</Button>
                            <Button type='submit' variant='light' disabled={this.state.disabledDeleteButton}
                            onClick={this.deleteItem}>Delete</Button>
                        </Col>
                    </Row>
                    <Modal show={this.state.showModal} backdrop="static">
                        <Modal.Header>
                            <Modal.Title>Add New Item</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label htmlFor="description">Description: </label>
                            <input type="text" className="form-control" id="description" name="description" onChange={this.handleInputChange} /><br></br>
                            <label htmlFor="price">Price: </label>
                            <input type="text" className="form-control" id="price" name="price" onChange={this.handleInputChange} /><br></br>
                            <label htmlFor="available_at">Available At: </label>
                            <input type="text" className="form-control" id="availableAt" name="availableAt" onChange={this.handleInputChange} /><br></br>
                            <label htmlFor="link">Link: </label>
                            <input type="text" className="form-control" id="link" name="link" onChange={this.handleInputChange}/><br></br>
                            <label htmlFor="size">Size\Quantity: </label>
                            <input type="text" className="form-control" id="size" name="size" onChange={this.handleInputChange} /><br></br>
                        </Modal.Body> 
                        <Modal.Footer>
                            <Button variant="seconary" onClick={this.handleHideAddItemModal}>Close</Button>
                            <Button variant="primary" onClick={this.addItem}>Submit</Button>
                        </Modal.Footer>   
                    </Modal>
                    <Modal show={this.state.showEditModal} backdrop="static"> 
                        <Modal.Header>
                            <Modal.Title>How to Edit My List</Modal.Title>    
                        </Modal.Header>
                        <Modal.Body>
                            <h3>Add an Item</h3>
                                <ol>
                                    <li>Click 'Add New Item' Button</li>
                                    <li>Enter item details (don't forget to add size/quantity!)</li>
                                    <li>Click 'Submit'</li>
                                </ol>
                            <h3>Edit an Item</h3>
                                <ol>
                                    <li>Click on the cell you want to edit (text box should now appear)</li>
                                    <li>Make any necessary changes</li>
                                    <li>Hit Enter to save</li>
                                    <li>If you want to exit without saving, click anywhere outside of text box</li>
                                </ol>
                            <h3>Delete an Item</h3>
                                <ol>
                                    <li>Click the checkbox of the item(s) you want to delete</li>
                                    <li>Click 'Delete' button</li>
                                </ol>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.handleHideEditItemModal}>Close</Button>
                        </Modal.Footer>      
                    </Modal>   
                </Container>
            </div>
        );
    }
}
export default MyList;